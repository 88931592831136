import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import IconWithText from '@shared/ui/display/IconWIthText';
import Button from '@shared/ui/buttons/Button';
import { StepContainer } from '@widgets/sidebars/shared';
import { CheckmarkIcon, StepBodyText, } from '../shared';
const FinalStep = ({ goToNextStep, isVerified = false }) => {
    const { t } = useTranslation();
    const handleClick = () => {
        if (goToNextStep) {
            goToNextStep();
        }
    };
    return (_jsx(StepContainer, { content: (_jsxs(_Fragment, { children: [_jsx(IconWithText, { IconElement: _jsx(CheckmarkIcon, {}), text: t('profile.create_trader.steps.final.title', { ns: 'common' }) }), isVerified ? (_jsx(StepBodyText, { children: t('profile.create_trader.steps.final.verification_description', { ns: 'common' }) })) :
                    null] })), contentFlex: 1, buttons: (_jsx(Button, { variant: 'filled', color: 'primary', size: 'large', fullWidth: true, onClick: handleClick, children: t('profile.create_trader.steps.final.btn_text', { ns: 'common' }) })) }));
};
export default FinalStep;
